.btn {
  min-width: 100px;
  min-height: 30px;
  width: 150px !important;
  height: 40px !important;
  font-size: 1em;
  border-radius: 8px;
  cursor: pointer;
  border: 2px solid #371b58 !important;
  filter: drop-shadow(0.2rem 0.2rem 0.5rem rgba(55, 27, 88, 0.5));
  -webkit-transition-duration: 0.2s; /* Safari */
  transition-duration: 0.2s !important;
  text-decoration: none !important;
  overflow: hidden !important;
  cursor: pointer !important;
}

.primary {
  color: #fff !important;
  background-color: #371b58 !important;
}

.primary:hover {
  color: #371b58 !important;
  background-color: #fff !important;
}

.secondary {
  color: #371b58 !important;
  background-color: #fff !important;
}

.secondary:hover {
  color: #fff !important;
  background-color: #371b58 !important;
}

.disabled,
.disabled:hover {
  background-color: #fff !important;
  color: #00000070 !important;
  border-color: #371b5860 !important;
}
