.first-section {
  color: #fff;
}

.socialmediaicons {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  height: 100px;
  width: auto;
}

.first-section h2 {
  font-size: 22px;
}

.SBDLogo {
  /* height: auto; */
  /* width: 100%; */
  cursor: pointer;
}

.icon-footer-section {
  color: #fff;
  font-size: 1.5em;
}
