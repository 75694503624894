.section-ilustartion {
  object-fit: contain;
  object-position: right;
}
#ilustration-description {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#title-concept {
  min-width: 300px;
  max-width: 500px;
  font-size: 3em;
}
#title-concept::first-line {
  color: rgba(55, 27, 88, 0.8);
}
.description-hero {
  font-size: 1.5em;
}
