.section-ilustartion {
  object-fit: contain;
  object-position: right;
}
#ilustration-description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
#title-about-hero {
  max-width: 500px;
  font-size: 3em;
  font-weight: 500;
  text-align: center;
}
.title-who {
  padding: 0px 15px 0px 0px;
  color: rgba(55, 27, 88, 0.8);
  opacity: 0;
  animation: opacity 3s forwards;
}
.title-we {
  padding: 0px 15px 0px 0px;
  opacity: 0;
  animation: opacity 3s forwards;
  animation-delay: 1s;
}
.title-are {
  opacity: 0;
  animation: opacity 3s forwards;
  animation-delay: 2s;
}
@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
#contact-button {
  min-width: 100px;
  max-width: 200px;
}
.description-hero {
  font-size: 1.5em;
}
