.section-career-hero-ilustration {
  object-fit: contain;
  object-position: right;
}
#career-hero-description {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#title-career {
  font-size: 3em;
  font-weight: 500;
}
.title-remote {
  color: rgba(55, 27, 88, 0.8);
  animation: opacity 3s forwards;
  opacity: 0;
}
.title-staff {
  opacity: 0;
  animation: opacity 3s forwards;
  animation-delay: 1s;
}
@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.description-hero {
  font-size: 1.5em;
}
