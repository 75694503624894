.continer-image {
  border-radius: 50%;
  object-fit: cover;
}
.member-name {
  font-size: 1.5em;
  font-weight: 600;
  color: #371b51;
}
.member-details {
  text-align: center;
  font-size: 1.3em;
  height: 60px;
}
.member-email,
.member-email:hover {
  text-decoration: none;
  color: #371b51;
  font-size: 0.8em;
  cursor: pointer;
}
.member-link {
  width: 50px !important;
  height: 40px !important;
  cursor: pointer;
  color: #371b51;
  text-decoration: none;
  font-size: 2em;
}
.member-profile-avatar {
  height: 110px;
  width: 110px;
  object-fit: cover;
  object-position: center;
}
.member-email-text:hover {
  color: #371b51;
  text-decoration: underline;
}
.member-link:hover {
  background-color: #371b51;
  color: #fff;
}
.icon {
  font-size: 1.5em;
}
.error-message {
  font-size: 1.8em;
  font-weight: 700;
}
.container-member-profile {
  object-fit: cover;
  object-position: center;
  background-color: #fff;
}
