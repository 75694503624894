$dark: #A9A9A9;
$darkLower: #22303f8c;
$grey: #8395a7;
$white: #fff;
$primary: #7C14FD;

.like-button-wrapper {
  position: relative;
  display: grid;
  place-items: center;
  border: none;
  cursor: pointer;
  padding: 16px 24px;
  color: $white;
  font-size: 16px;
  font-weight: 600;
  background-color: transparent;

  &::before {
    content: "";
    z-index: 1;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    will-change: background-color;
    transition: background-color 0.3s, transform 0.3s;
    background-color: $dark;
    box-shadow: 0 0 10px $darkLower;
    border-radius: 8px;
  }

  &:hover:not(.liked) {
    svg {
      transform: translateY(-2px) rotate(8deg);
      #thumb-end {
        transform: rotate(45deg) translate(5px, -45px);
      }
    }
  }

  &.clicked {
    &::before {
      animation: click 300ms;
    }
  }

  &.liked {
    &::before {
      background-color: $primary;
    }
    svg {
      animation: hop 500ms;
    }
  }

  .like-button {
    display: flex;
    align-items: center;
    z-index: 1;
    transform: translateX(3px);
  }

  .particles {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    .particle-rotate {
      position: absolute;

      &:nth-child(1) {
        right: 0;
        top: 50%;
      }

      &:nth-child(2) {
        right: 0;
        bottom: 0;
      }

      &:nth-child(3) {
        right: 33%;
        bottom: 0;
      }
      &:nth-child(4) {
        right: 66%;
        bottom: 0;
      }
      &:nth-child(5) {
        left: 0;
        bottom: 0;
      }
      &:nth-child(6) {
        left: 0;
        bottom: 50%;
      }
      &:nth-child(7) {
        left: 0;
        top: 0;
      }
      &:nth-child(8) {
        left: 33%;
        top: 0;
      }
      &:nth-child(9) {
        left: 66%;
        top: 0;
      }
      &:nth-child(10) {
        right: 0;
        top: 0;
      }
    }

    .particle-tick {
      position: absolute;
      z-index: -1;
      width: 10px;
      height: 1px;
      background-color: $primary;
      animation: boom 500ms;
      transform: translateX(-25px);
    }
  }

  svg {
    width: 22px;
    height: 22px;
    margin-right: 8px;
    transform: translateY(-2px);
    transition: transform 0.2s;

    #thumb-end {
      transition: transform 0.2s;
    }
  }

  .suffix {
    opacity: 0;
    transition: opacity 300ms, transform 300ms;
    transform: translateX(15px);

    &.liked {
      opacity: 1;
      transform: translateX(0);
    }
  }
}

@keyframes hop {
  0% {
    transform: rotate(8deg) translateY(-2px);
  }
  30% {
    transform: rotate(-14deg) translateY(-5px);
  }
  65% {
    transform: rotate(7deg) translateY(2px);
  }
  100% {
    transform: rotate(0deg) translateY(-2px);
  }
}

@keyframes click {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.96);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes boom {
  0% {
    transform: translateX(-25px);
    opacity: 1;
  }
  100% {
    transform: translateX(50px);
    opacity: 0;
  }
}