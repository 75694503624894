#card-description {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
#card-description h3,
#card-description p {
  max-width: 500px;
}
@media only screen and (max-width: 600px) {
  #card-icon {
    max-width: 300px;
    min-width: 200px;
    width: 100%;
  }
}
