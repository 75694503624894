#title-what-we-do {
  max-width: 250px;
  color: #ffffff;
  font-size: 2em;
  margin: 20px;
  text-align: center;
}
#title-what-we-do::first-letter {
  font-size: 1.5em;
  color: #ffffff;
}
#description-what-we-do {
  max-width: 800px;
  text-align: center;
  font-size: 1.5em;
  margin-bottom: 50px;
  font-weight: 600;
  color: #ffffff;
}
#section-card-home {
  align-content: center;
}
