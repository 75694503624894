#home-email-section {
  background-image: url("../../../../assets/background/arrows-background.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 500px;
  min-width: 300px;
  max-width: 100%;
}
#title-home-email {
  color: #fff;
  font-size: 2em;
  max-width: 300px;
}
#continer-email {
  height: 500px;
}
#home-email {
  min-width: 250px;
  max-width: 2800px;
  min-height: 2.5em;
}
#send-adress {
  min-width: 250px !important;
  max-width: 2800px !important;
  font-weight: 600;
  color: #351b71 !important;
}
#send-adress:hover {
  border: 2px solid #fff !important;
  color: #fff !important;
}
.input-message {
  color: #fff;
}
