.advantage-list-title {
  font-size: 1.6em;
  color: #351b71;
}
.color-black {
  color: #000;
  margin-top: 10px;
}
.section-apply-form {
  height: 5px;
  background: linear-gradient(
    89.99deg,
    #371b51 1.09%,
    rgba(124, 20, 253, 0.29) 99.99%
  );
  border-radius: 500px;
}

.subtitle-advantages {
  font-size: 1.8em;
}

@media only screen and (max-width: 767px) {
  .subtitle-advantages {
    font-size: 1.5em;
    text-align: center;
  }
}
