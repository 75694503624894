.navbar-toggler {
  border: none;
  color: transparent;
}
.logo img,
.logo-drop {
  max-width: 200px;
  max-height: 20%;
  height: auto;
  width: 200px;
  cursor: pointer;
}
.logo {
  padding-left: 20px;
  margin-top: 12px;
  margin-bottom: 12px;
}
.distinct {
  align-self: center;
  background-color: #fff;
  width: 150px;
  height: 40px;
  border: 1px solid #7c14fd;
  border-radius: 15px;
  color: #7c14fd;
  transition: box-shadow, width, height 0.3s;
  margin-right: 20px;
}
@media only screen and (max-width: 991px) {
  .distinct {
    align-self: start;
  }
}
.container-navigation {
  background-color: #fff;
}
.distinct:hover {
  box-shadow: 4px 4px 12px #371b51;
  width: 150px;
  height: 50px;
}
.menu-close-button {
  color: #371b51;
  font-size: 1.5em;
  cursor: pointer;
}
