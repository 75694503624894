.second-section {
  color: #fff;
}

.company-button {
  background-color: transparent;
  color: #fff;
  border: none;
  margin-left: 20px;
}
.pointer {
  cursor: pointer;
}
