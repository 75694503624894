#continer-testimonial {
  display: flex;
  flex-direction: row;
  margin: 50px 0px 50px 0px;
}
.author-testimonial {
  color: #7c14fd;
}
.text-testimonial {
  color: #371b51;
  font-size: 1.5em;
}
@media only screen and (max-width: 700px) {
  .text-testimonial {
    font-size: 1em;
  }
}
