.title-blog-section {
  font-size: 2.5em;
  color: #ffffff;
}
.description-blog-section {
  font-size: 1.5em;
  font-weight: 600;
  color: #ffffff;
}
#blog-card-section {
  object-position: center;
  min-width: 300px;
  max-width: 100%;
}
