.menu-tabs {
  text-decoration: none;
  padding: 10px 10px;
  font-size: 18px;
  background-color: transparent;
  transition: color 1s;
}

.menu-tabs:hover {
  color: #7c14fd;
}
.menu-tabs-container {
  border: 1px solid transparent;
  display: flex;
  cursor: pointer;
  transition: padding 1s;
}
.menu-tabs-container:hover {
  border-bottom: 1px solid #7c14fd !important;
  transition: border 1s;
}

.active-tabs {
  color: #7c14fd;
  border-bottom: 1px solid #7c14fd !important;
}
