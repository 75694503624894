.titlu-section-members {
  font-size: 3em;
  min-width: 300px;
  max-width: 500px;
  color: #ffffff;
}
.description-section-member {
  font-size: 1.5em;
  font-weight: 600;
  color: #ffffff;
}
/* #header-section-members{
    min-height: 300px;
    margin-top: 200px;
    margin-bottom: 200px;
} */
