.form-apply-courses {
  min-width: 600px;
}

.text-before-apply-form {
  font-size: 1.7em;
}

@media only screen and (max-width: 767px) {
  .form-apply-courses {
    min-width: 200px;
  }
  .text-before-apply-form {
    font-size: 1.5em;
  }
}
