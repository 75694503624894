#continer-form-section {
  padding: 150px 15px 150px 15px !important;
  background-size: cover;
  background-image: url("../../assets/background/contactUsBackground.svg");
}
#container-contact-hero {
  min-height: 200px;
  font-weight: 500;
  font-size: 3em;
}
.title-contact {
  color: rgba(55, 27, 88, 0.8);
  padding-right: 15px;
  opacity: 0;
  animation: opacity 3s forwards;
}
.title-us {
  opacity: 0;
  animation: opacity 3s forwards;
  animation-delay: 1s;
}
@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
