.title-card-blog {
  color: #371b51;
  font-size: 2em;
  line-height: 1;
}

.subtitle-card-blog {
  font-size: 1.5em;
}

.description-card-blog {
  font-size: 1em;
}

.card-avatar {
  object-fit: contain;
}

.title-post-blog {
  color: #371b51;
  font-size: 1.2em;
}

.subtitle-post-blog {
  font-size: 1em;
}

.container-content-blog-card {
  height: 100% !important;
}

.modal-post-data {
  width: 40px !important;
}

.error-message {
  font-size: 1.8em;
  font-weight: 700;
}

.modal-dialog .modal-xl {
  width: 100% !important;
}
