#footer-bg {
  background-color: #000;
}

.wave {
  background: url("../../assets/images/footer-wave-background.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 750px;
}

.wave-width {
  width: 50%;
}

@media (max-width: 1000px) {
  .wave {
    background: #371b51;
    min-height: 600px;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
  }
}

@media (max-width: 1200px) {
  .wave-width {
    width: 75%;
  }
}

.wave-items {
  z-index: 2;
}

.copy-right-text {
  color: #fff;
}
