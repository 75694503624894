.section-ilustration-remote-card {
  object-fit: contain;
  object-position: right;
}
#remote-card-title {
  justify-content: center;
}
#title-remote-card {
  min-width: 200px;
  max-width: 300px;
  font-size: 2em;
  text-align: center;
}
#title-remote-card::first-line {
  color: rgba(55, 27, 88, 0.8);
}
#remote-card-button {
  min-width: 100px;
  max-width: 200px;
}
.description-remote-card {
  font-size: 1.5em;
}
