#title-services {
  font-size: 3em;
  font-weight: 500;
  color: rgba(55, 27, 88, 0.8);
  animation: opacity 3s forwards;
  opacity: 0;
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.service-header p {
  font-size: 1.5em;
}

.contact-us {
  color: #fff;
}
