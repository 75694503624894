.gradient-line {
  height: 5px;
  background: linear-gradient(
    89.99deg,
    #371b51 1.09%,
    rgba(124, 20, 253, 0.29) 99.99%
  );
  border-radius: 500px;
}

.icon {
  color: #371b51;
}

.sbd-input {
  background-color: #fbf1fc;
  width: 200px;
  border-top: #371b51 solid 3px;
  border-radius: 7px;
}

#section-form {
  background-color: #fff;
}

/* *********  error notification style********** */
.notification {
  width: 250px;
  transition: all 0.5s;
}

.red-error {
  background-color: rgba(255, 0, 0, 0.2);
  color: #371b51;
}

.gray-error {
  background-color: rgba(0, 0, 0, 0.2);
  color: #371b51;
}
