.pagenotfound {
  color: red;
}
/*  */
/* .error-bg {
    background-image: url('../../assets/background/cinci.png');
} */

.first-p {
  font-size: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(55, 27, 88, 0.8);
  animation: opacity 3s forwards;
  opacity: 0;
}
.second-p {
  font-size: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(55, 27, 88, 0.8);
  animation: opacity 3s forwards;
  opacity: 0;
  animation-delay: 1s;
}

.ErrorSBDLOGO {
  height: 100px;
  cursor: pointer;
}
