.top-to-btm {
  position: fixed;
  bottom: 40px;
  right: 30px;
  z-index: 20;
}
.icon-style {
  background-color: #371b51;
  border: 2px solid #fff;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  color: #fff;
  cursor: pointer;
  animation: movebtn 3s ease-in-out infinite;
  transition: all 0.5s ease-in-out;
}

.icon-style:hover {
  animation: none;
  background: #fff;
  color: #371b51;
  border: 2px solid #371b51;
}

@keyframes movebtn {
  0% {
    transform: translateY(0px);
  }

  25% {
    transform: translateY(20px);
  }

  50% {
    transform: translateY(0px);
  }

  75% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0px);
  }
}
